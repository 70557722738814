import {
  LANDING,
  HOME,
  SIGNUP,
  SLOT_BOOKING,
  ONBOARDING,
  PLAN,
  E500,
  E404,
} from "./paths";
import {
  Home,
  Signup,
  SlotBooking,
  Plan,
  InternalServerError,
  PageNotFound,
} from "../pages/index";

const LANDING_ONE = {
  component: Home,
  path: LANDING,
};

const HOME_TWO = {
  component: Home,
  path: HOME,
};

const SLOT_BOOKING_THREE = {
  component: SlotBooking,
  path: SLOT_BOOKING,
};

const ONBOARDING_FOUR = {
  component: Home,
  path: ONBOARDING,
};

const PLAN_FIVE = {
  component: Plan,
  path: PLAN,
};

const INTERNAL_SERVER_ERROR_PAGE_SIX = {
  component: InternalServerError,
  path: E500,
};

const PAGE_NOT_FOUND_PAGE_SEVEN = {
  component: PageNotFound,
  path: E404,
};

const SIGNUP_EIGHT = {
  component: Signup,
  path: SIGNUP,
};

const routes = [
  LANDING_ONE,
  HOME_TWO,
  SLOT_BOOKING_THREE,
  ONBOARDING_FOUR,
  PLAN_FIVE,
  INTERNAL_SERVER_ERROR_PAGE_SIX,
  PAGE_NOT_FOUND_PAGE_SEVEN,
  SIGNUP_EIGHT,
];

export default routes;
