import React, { useState, useRef, useEffect } from "react";
import { useBtnContext } from "../../contexts";

export const YesActionNext = ({ HeadingText, isShow, unique_key }) => {
  const [plusMinusDays, setPlusMinusDays] = useState(0);
  const [stringDay, setStringDay] = useState("Days");
  const { setIsBtnDisabled, setReturnDays, setExchangeDays } = useBtnContext();
  const dayRef = useRef();
  const plusMinusDaysHandler = () => {
    if (parseInt(dayRef?.current.value) > 0) {
      setReturnDays(dayRef?.current.value);
      setExchangeDays(dayRef?.current.value);
      setIsBtnDisabled(false);

      if (dayRef?.current.value > 1) {
        setStringDay("Days");
      } else {
        setStringDay("Day");
      }
      if (dayRef?.current.value) {
        setReturnDays(dayRef?.current.value);
        setExchangeDays(dayRef?.current.value);
      }
    } else {
      setIsBtnDisabled(true);
      setStringDay("Day");
    }
    setPlusMinusDays(dayRef.current.value);
  };

  const clearDefaultValue = (e) => {
    e.target.value = "";
    setIsBtnDisabled(true);
  };

  useEffect(() => {
    plusMinusDays > 0 ? setIsBtnDisabled(false) : setIsBtnDisabled(true);
  }, [plusMinusDays, setIsBtnDisabled]);

  useEffect(() => {
    if (isShow === false) {
      console.log(typeof isShow);
      // dayRef.current.value = 7;
      setStringDay("Days");
    }
  }, [isShow]);

  console.log(unique_key);
  return (
    <div
      className={`${isShow ? "show visible" : "answer"}`}
      style={{ paddingTop: "40px" }}
    >
      <h2 className="inner__title">{HeadingText}</h2>
      <div className="number">
        <input
          ref={dayRef}
          type="text"
          className="number__input"
          defaultValue="7"
          maxLength="3"
          key={unique_key}
          onFocus={(e) => {
            clearDefaultValue(e);
          }}
          onChange={plusMinusDaysHandler}
        />
        <span className="window__day">{stringDay}</span>
      </div>
    </div>
  );
};
