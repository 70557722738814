import React, { useState, useRef, useEffect } from "react";
import { useBtnContext } from "../../contexts";
export const SearchDropDown = ({ Logistics }) => {
  const [isShowAddList, setShowAddList] = useState(false);
  const [addList, setAddList] = useState([]);
  const [inputStr, setInputStr] = useState();
  const [activeList, setActiveList] = useState(0);
  const { setIsBtnDisabled, setLogistics } = useBtnContext();
  const [current, setCurrent] = useState(null);
  const [selectedLogistic, setSelectedLogistic] = useState([]);
  const [inputKey, setInputKey] = useState();
  const [searchStr, setSearchStr] = useState("");
  const logisticsRef = useRef();
  const listRef = useRef();
  //Fix this function
  const searchHandler = (e) => {
    setSearchStr(e.target.value);
    if (e.target.value) {
      const searchStringInLower = e.target.value.toLowerCase();
      const filterData = Logistics.filter((item) => {
        return item.toLowerCase().includes(searchStringInLower);
      });
      console.log(filterData);
      if (filterData.length > 0) {
        const sort = filterData.sort();
        // const split = sort[0];
        setSelectedLogistic(sort);
        setShowAddList(true);
      } else {
        setSelectedLogistic(null);
        setShowAddList(false);
      }
    } else {
      setSelectedLogistic(null);
      setShowAddList(false);
    }
  };

  const addListHandler = () => {
    if (searchStr) {
      setAddList((prev) => {
        return [...prev, searchStr];
      });
      setLogistics((prev) => {
        return [...prev, searchStr];
      });

      setTimeout(() => {
        setSearchStr("");
      }, 500);

      if (addList) {
        setIsBtnDisabled(false);
      } else {
        setIsBtnDisabled(true);
      }
      setInputStr("");
    }
  };

  const addListToInput = (index) => {
    setInputStr(selectedLogistic[index]);
    // logisticsRef.current.value = selectedLogistic[index];
    logisticsRef.current.focus();
    setShowAddList(false);
  };

  const removeTargetItem = (itemToRemove) => {
    if (itemToRemove === "lengthOne") {
      setAddList([]);
      setIsBtnDisabled(true);
    } else {
      const remainingItems = addList.filter((item) => item !== itemToRemove);
      setAddList(remainingItems);
      setLogistics(remainingItems);
    }
  };

  const selectListHandler = (e, index) => {
    if (e.key === "ArrowDown") {
      if (current === null) {
        setCurrent(0);
      }

      if (current !== null && current < selectedLogistic?.length - 1) {
        setCurrent((prev) => prev + 1);
      }
      if (current !== null && current > selectedLogistic?.length - 1) {
        setCurrent((prev) => prev + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (current === null) {
        return;
      }
      if (setCurrent !== 0) {
        setCurrent((prev) => prev - 1);
      }
    } else {
      setActiveList(false);
    }
  };
  // console.log(current, selectedLogistic[current], addList);

  useEffect(() => {
    setSearchStr("");
    setShowAddList(false);
    setCurrent(null);
  }, [addList]);
  return (
    <div className="add">
      <div className="add__search">
        <span className="add__warning">This provider has been added</span>
        <input
          type="text"
          placeholder="Search for logistics Providers"
          className="add__input"
          value={searchStr}
          onChange={(e) => searchHandler(e)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (selectedLogistic?.length > 0 && current !== null) {
                return setAddList((prev) => {
                  return [...prev, selectedLogistic[current]];
                });
              }
              return setAddList((prev) => {
                return [...prev, searchStr];
              });
            }
          }}
          onKeyDown={(e) => selectListHandler(e)}
        />

        <button className="add__circle circle" onClick={addListHandler}>
          <svg className="add__icon">
            <use xlinkHref="assets/svg/sprite.svg#plus-2"></use>
          </svg>
        </button>
        <ul
          className={`add__list`}
          style={{
            display: `${isShowAddList ? "block" : "none"}`,
          }}
        >
          {selectedLogistic?.map((item, index) => {
            return (
              <li
                key={index}
                // onClick={() => {
                //   addListToInput(index);
                // }}
                // className={`add__item ${
                //   activeList === index && "add__item choose"
                // }`}
                style={{}}
                className="add__item"
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>
      <ul id="target__list">
        {addList?.length > 1 &&
          addList?.map((item, index) => {
            return (
              <li
                className="target__item"
                key={index}
                onClick={() => removeTargetItem(item)}
              >
                {item}
                <button className="target__btn circle">
                  <svg className="target__icon">
                    <use xlinkHref="assets/svg/sprite.svg#close"></use>
                  </svg>
                </button>
              </li>
            );
          })}

        {addList.length === 1 && (
          <li
            className="target__item"
            onClick={() => removeTargetItem("lengthOne")}
          >
            {addList}
            <button className="target__btn circle">
              <svg className="target__icon">
                <use xlinkHref="assets/svg/sprite.svg#close"></use>
              </svg>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
