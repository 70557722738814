import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "./pages";
import routes from "./routing/routes";

// import FullStory from "react-fullstory";
// import { FullStoryAPI } from "react-fullstory";
import insp, { install } from "inspectlet-es";
const App = () => {
  // const ORG_ID = "16MZJ0";
  useEffect(() => {
    const browserTab = document.querySelector("#browser__tab"),
      browserText = browserTab.textContent;

    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        browserTab.innerHTML = "👋 Come back 😭";
      } else {
        browserTab.innerHTML = "Thanks 🤗";
        setTimeout(() => {
          browserTab.innerHTML = browserText;
        }, 1000);
      }
    });
  }, []);

  console.log(localStorage.getItem("StoreName"));
  // useEffect(() => {
  //   install(902273069);
  //   insp(["identify", localStorage.getItem("StoreName")]);
  //   window.__insp.push(["identify", localStorage.getItem("StoreName")]);
  // }, []);

  return (
    <div className="workspace">
      {/* <FullStory org={ORG_ID} /> */}
      <Routes>
        {routes.map((route, key) => (
          <Route path={route.path} element={<route.component />} key={key} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
