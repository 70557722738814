import React, { useState } from "react";

export const TextInput = ({ type, name, place, onChangeValue }) => {
  const [textValue, setTextValue] = useState("");

  const handleChange = (e) => {
    setTextValue(e.target.value)
    onChangeValue(e.target.value)
  }

  return (
      <div className="text__wrapper">
        <label className="text__label">
          <div className="text__label__heading">{name}</div>
          <input
            type={type}
            className="text__input"
            defaultValue={""}
            placeholder={place}
            onChange={handleChange}
            value={textValue}
          />
        </label>
      </div>
  );
};
