import React, { useState, useEffect } from "react";
import { useBtnContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  RightTitle,
  RightInnerDesc,
  YesNo,
  YesActionNext,
  Category,
  SearchDropDown,
  Button,
  TimerAndPageNo,
} from "..";
export const RightSide = ({
  RightSideTitleText,
  InnerDesc,
  isShowYesNo,
  YesActionText,
  CheckBoxText,
  Logistics,
  LoadContent,
  RightSideAnimation,
  ActivePage,
  LastPage,
  radioChecked,
}) => {
  const [yesNextActionToggle, setYesNextActionToggle] = useState(false);
  const {
    isBtnDisabled,
    setIsBtnDisabled,
    category,
    logistics,
    isExchange,
    setIsExchange,
    logisticProviders,
    returnDays,
    setReturnDays,
    exchangeDays,
    setExchangeDays,
    reduceReturns,
    controlRefundFraud,
    neighborhoodExchangePreferred,
  } = useBtnContext();
  const navigate = useNavigate();
  const yesNoHandler = (choice) => {
    if (choice === "yes") {
      if (ActivePage === 1) {
        // setReturnDays(7);
      } else if (ActivePage === 2) {
        setIsExchange(true);
        // setExchangeDays(7);
      }

      setYesNextActionToggle(true);
      // setIsBtnDisabled(true);
      setIsBtnDisabled(false);
    } else {
      if (ActivePage === 1) {
        setReturnDays("false");
      } else if (ActivePage === 2) {
        setIsExchange(false);
        setExchangeDays("false");
      }
      setYesNextActionToggle(false);
      setIsBtnDisabled(false);
    }
  };

  const saveData = async () => {
    const uid = localStorage.getItem("uid");

    if (ActivePage === 1) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=return-window&days=${returnDays}`
        );
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    }
    if (ActivePage === 2) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=exchange&accept_exchanges=${isExchange}&days=${exchangeDays}`
        );

        // const response2 = await axios.post(
        //   `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&exchange&days=${exchangeDays}`
        // );
        // console.log(response2);
      } catch (error) {
        setIsBtnDisabled(true);
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    } else if (ActivePage === 3) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=product-categories`,
          {
            active_product_categories: category,
          }
          );
          console.log(response);
      } catch (error) {
        setIsBtnDisabled(true);
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    } else if (ActivePage === 4) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=logistic-providers`,
          {
            logistic_providers: logistics,
          }
        );
      } catch (error) {
        setIsBtnDisabled(true);
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    } else if (ActivePage === 5) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=ai-preferences`,
          {
            reduce_returns: reduceReturns,
            control_return_fraud: controlRefundFraud,
            neighborhood_exchange_preferred: neighborhoodExchangePreferred,
          }
        );
      } catch (error) {
        setIsBtnDisabled(true);
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }


    }
  };

  useEffect(() => {
    if (ActivePage >= 1 && ActivePage <= 5) {
      // setIsBtnDisabled(true);
      setYesNextActionToggle(false);
    }
  }, [ActivePage, setIsBtnDisabled]);
  return (
    <div className={`side`}>
      <div className="main">
        <div className={`right animate__animated ${RightSideAnimation}`}>
          <section className={`right__content show `}>
            <div className={`inner `}>
              <div className={`inner__box `}>
                <RightTitle TitleText={RightSideTitleText} />
                {isShowYesNo && RightSideTitleText.length === 1 && (
                  <YesNo
                    onClick={(e) => {
                      yesNoHandler(e.target.value);
                    }}
                    name={ActivePage === 1 ? "return" : "exchange"}
                    checked={radioChecked}
                  />
                )}
                {YesActionText && (
                  <YesActionNext
                    HeadingText={YesActionText}
                    isShow={yesNextActionToggle}
                    unique_key={ActivePage === 1 ? "return" : "exchange"}
                  />
                )}
                {CheckBoxText?.length > 1 && ActivePage === 3 && (
                  <Category CheckBoxText={CheckBoxText} />
                )}
                <RightInnerDesc InnerDesc={InnerDesc} />
                {logisticProviders?.length > 0 && ActivePage === 4 && (
                  <SearchDropDown Logistics={logisticProviders} />
                )}
              </div>
              <Button
                name="Continue"
                className=""
                onClick={() => {
                  LoadContent();
                  saveData();
                }}
                isBtnDisabled={isBtnDisabled}
              />
            </div>
          </section>
        </div>
        {ActivePage > 0 && ActivePage <= 5 && (
          <TimerAndPageNo ActivePage={ActivePage} LastPage={LastPage} />
        )}
      </div>
    </div>
  );
};
